import { cx } from "@uxf/core/utils/cx";
import React, { FC, PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
    className?: string;
}

export const TextWrapper: FC<Props> = ({ children, className }) => {
    return (
        <div
            className={cx(
                "prose-natural prose-h2:ui-typo-h3 prose-h3:ui-typo-medium prose-p:ui-typo-body prose-li:ui-typo-body prose prose-xl max-w-3xl prose-h2:mt-0 prose-h2:leading-snug prose-p:opacity-80 prose-li:opacity-80",
                className,
            )}
        >
            {children}
        </div>
    );
};
