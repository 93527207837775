import { Project } from "@config/projects-config";
import { SectionLayout } from "@ui/components/section-layout/section-layout";
import { ProjectTile } from "@ui/components/project-tile/project-tile";
import React, { memo, useState } from "react";
import { Button } from "@ui/components/button/button";
import { useTranslation } from "next-i18next";

interface Props {
    className?: string;
    description?: string;
    isPage?: boolean;
    layoutClassName?: string;
    limit?: number;
    name: string;
    projects: Project[];
    title: string;
    variant?: "violet" | "white";
}

const ADD_COUNT = 4;

export const ReferenceSection = memo<Props>(
    ({ layoutClassName, limit = 8, projects, variant = "white", ...layoutProps }) => {
        const { t } = useTranslation();
        const [countToDisplay, setCountToDisplay] = useState(limit);
        const displayedProjects = countToDisplay ? projects.slice(0, countToDisplay) : projects;

        return (
            <SectionLayout className={layoutClassName} variant={variant} {...layoutProps}>
                <ul className="grid gap-y-20 md:grid-cols-2 md:gap-x-12 md:pt-40 lg:gap-x-32 lg:gap-y-40">
                    {displayedProjects.map(project => (
                        <ProjectTile
                            className="relative md:odd:-mt-40"
                            key={project.name}
                            urlVariant={variant === "white" ? "violet" : "white"}
                            {...project}
                        />
                    ))}
                </ul>
                {projects.length > displayedProjects.length && (
                    <div
                        className={`mx-auto mt-20 w-fit ${
                            displayedProjects.length % 2 === 0 ? "md:mt-16" : "md:-mt-20"
                        }`}
                    >
                        <Button variant="violet" outlined onClick={() => setCountToDisplay(prev => prev + ADD_COUNT)}>
                            {t("reference:section.showMore")}
                        </Button>
                    </div>
                )}
            </SectionLayout>
        );
    },
);

ReferenceSection.displayName = "ReferenceSection";
