import { BLOG_ARTICLES, BlogKeys } from "@config/blog-config";
import { TextWrapper } from "@shared/components/blog/text-wrapper";
import { useCarousel } from "@shared/hooks/use-carousel";
import { useLocale } from "@translations/locale-context";
import { SectionLayout } from "@ui/components/section-layout/section-layout";
import { TextLink } from "@ui/components/text-link/text-link";
import { useTranslation } from "next-i18next";
import Image from "next/legacy/image";
import Link from "next/link";
import React, { FC, Fragment, memo, ReactNode, Ref } from "react";

type ContentProps = {
    isInsideSection?: boolean;
    skipArticleByKey?: BlogKeys;
    title?: string;
};

type Props = ContentProps & ({ hasLayout: true; name?: string } | { hasLayout?: false; name?: never });

const Content: FC<ContentProps & { emblaRef: Ref<any>; scrollArrows: ReactNode }> = ({
    emblaRef,
    isInsideSection,
    scrollArrows,
    skipArticleByKey,
}) => {
    const locale = useLocale();
    const { t } = useTranslation();

    const titleClassName = "ui-typo-medium md:max-w-[30rem]";

    return (
        <>
            <div ref={emblaRef}>
                <div className="mt-12 flex gap-x-8 md:mt-20">
                    {BLOG_ARTICLES.filter(article => article.key !== skipArticleByKey)
                        .reverse()
                        .map(article => (
                            <div key={article.title} className="flex w-full shrink-0 flex-col sm:max-w-sm">
                                <div className="mb-8 w-full">
                                    <Link href={article.url} passHref legacyBehavior>
                                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                        <a className="sm:hover-stripes sm:hover-stripes--tr is-hoverable show-cursor block rounded-inherit outline-none">
                                            <Image alt="" layout="responsive" src={article.img} />
                                            <span className="sr-only">{t(article.title)}</span>
                                        </a>
                                    </Link>
                                </div>
                                <Link href={article.url} passHref legacyBehavior>
                                    <TextLink>
                                        {isInsideSection ? (
                                            <h3 className={titleClassName}>{t(article.title)}</h3>
                                        ) : (
                                            <h2 className={titleClassName}>{t(article.title)}</h2>
                                        )}
                                    </TextLink>
                                </Link>
                                <div className="ui-typo-body mt-4 flex flex-wrap text-uxfOrange">
                                    {article.hashtags.map(hashtag => (
                                        <Fragment key={hashtag.title}>
                                            {hashtag.href ? (
                                                <Link href={hashtag.href[locale]} passHref legacyBehavior>
                                                    <TextLink variant="orange" className="mr-4">
                                                        #{t(hashtag.title)}
                                                    </TextLink>
                                                </Link>
                                            ) : (
                                                <p className="mr-4">#{t(hashtag.title)}</p>
                                            )}
                                        </Fragment>
                                    ))}
                                </div>
                            </div>
                        ))}
                </div>
            </div>
            <div className="flex justify-center px-6 pt-10 md:hidden">{scrollArrows}</div>
            <div className="pointer-events-none absolute inset-0 hidden sm:flex">
                <div className="z-10 w-6 shrink-0 grow bg-gradient-to-r from-white md:w-auto" />
                <div className="container grow" />
                <div className="z-10 w-6 shrink-0 grow bg-gradient-to-l from-white md:w-auto" />
            </div>
        </>
    );
};

export const BlogCarouselSection = memo<Props>(({ name, title, skipArticleByKey, hasLayout = false }) => {
    const { t } = useTranslation();
    const [emblaRef, scrollArrows] = useCarousel();

    const layoutName = name ?? t("blog.name");
    const layoutTitle = title ?? t("blog.title");

    const content = (
        <Content
            emblaRef={emblaRef}
            isInsideSection={hasLayout}
            scrollArrows={scrollArrows}
            skipArticleByKey={skipArticleByKey}
            title={layoutTitle}
        />
    );

    return (
        <>
            {hasLayout ? (
                <SectionLayout
                    className="relative overflow-x-hidden sm:mb-12 md:mb-24"
                    description={<div className="absolute right-0 hidden md:block">{scrollArrows}</div>}
                    name={layoutName}
                    title={layoutTitle}
                >
                    {content}
                </SectionLayout>
            ) : (
                <div className="pb-28">
                    <div className="ui-container border-t-2 border-uxfViolet/5 pt-16">
                        <div className="relative">
                            <TextWrapper>
                                <h2>{layoutTitle}</h2>
                            </TextWrapper>
                            <div className="absolute right-0 top-0 hidden md:block">{scrollArrows}</div>
                        </div>
                    </div>
                    <div className="relative overflow-hidden">
                        <div className="ui-container">{content}</div>
                    </div>
                </div>
            )}
        </>
    );
});

BlogCarouselSection.displayName = "BlogCarouselSection";
