import { Project } from "@config/projects-config";
import { TextLink } from "@ui/components/text-link/text-link";
import { useAnchorProps } from "@uxf/core/hooks/useAnchorProps";
import Link from "next/link";
import React, { CSSProperties, Fragment, memo } from "react";
import { useTranslation } from "next-i18next";
import { StaticImage } from "@ui/components/image/static-image";
import { useLocale } from "@translations/locale-context";
import { cx } from "@uxf/core/utils/cx";

interface Props extends Project {
    className?: string;
    urlVariant: "violet" | "white";
}

export const ProjectTile = memo<Props>(
    ({ className, description, hashtags, name, imageSrc, url, urlVariant, hoverColor = "#140939" }) => {
        const { t } = useTranslation();
        const locale = useLocale();
        const anchorProps = useAnchorProps({
            className: cx(
                "rounded-inherit outline-none is-hoverable text-[var(--hover-color)] block",
                !!url && "sm:hover-stripes sm:hover-stripes--tr show-cursor",
            ),
            href: url,
            style: { "--hover-color": hoverColor } as CSSProperties,
            target: url && url.startsWith("http") ? "_blank" : undefined,
        });

        const image = (
            <StaticImage
                alt=""
                src={imageSrc}
                width={imageSrc.width}
                height={imageSrc.height}
                widths={[576, 768, 320, 392, 544]}
                imgClassName="z-1"
            />
        );

        return (
            <li className={className}>
                <div className="relative mb-6 flex sm:mb-8">
                    {anchorProps.href && !anchorProps.href.startsWith("http") ? (
                        <Link href={anchorProps.href} passHref legacyBehavior>
                            <a {...anchorProps}>
                                {image}
                                <span className="sr-only">{t(name)}</span>
                            </a>
                        </Link>
                    ) : (
                        <a {...anchorProps}>{image}</a>
                    )}
                </div>
                <div className="flex flex-col space-y-4">
                    {url && !url.startsWith("http") ? (
                        <Link href={url} passHref legacyBehavior>
                            <TextLink className="ui-typo-large" variant={urlVariant}>
                                {t(name)}
                            </TextLink>
                        </Link>
                    ) : (
                        <TextLink
                            className="ui-typo-large"
                            href={url}
                            rel="noopener noreferrer"
                            target="_blank"
                            variant={urlVariant}
                        >
                            {t(name)}
                        </TextLink>
                    )}
                    <p className="ui-typo-body">{t(description)}</p>
                    <p className="ui-typo-body text-uxfOrange">
                        {hashtags.map(hashtag => (
                            <Fragment key={hashtag.title}>
                                {hashtag.href ? (
                                    <Link href={hashtag.href[locale]} passHref legacyBehavior>
                                        <TextLink variant="orange" className="mr-3 inline-block last:mr-0">
                                            #{t(hashtag.title)}
                                        </TextLink>
                                    </Link>
                                ) : (
                                    <span className="mr-3 inline-block last:mr-0">#{t(hashtag.title)}</span>
                                )}
                            </Fragment>
                        ))}
                    </p>
                </div>
            </li>
        );
    },
);

ProjectTile.displayName = "ProjectTile";
